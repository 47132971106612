(<template>
  <section class="in-person-details">
    <slot></slot>
    <section v-if="contactExtraInfo"
             class="in-person-details__data-section">
      <h3 class="in-person-details__data-title">{{ $gettext('Directions') }}</h3>
      <p class="in-person-details__data">{{ contactExtraInfo }}</p>
    </section>
  </section>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        contractAttributes: ({jobCallDetails}) => {
          return jobCallDetails?.sessionDetails?.contactAttributes?.address || {};
        }
      }),
      contactExtraInfo() {
        return this.contractAttributes.extra;
      }
    }
  };
</script>

<style scoped>
  .in-person-details {
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .in-person-details__data-section {
    padding-top: 15px;
  }

  .in-person-details__data-title {
    display: block;
    width: 100%;
    color: #7a829d;
    font-weight: normal;
    font-size: 10px;
    line-height: 1.6;
  }

  .in-person-details__data {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
  }
</style>
